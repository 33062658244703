import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import chunk from 'lodash/chunk';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    headerImage,
    title,
    description,
    buttonWhiteText,
    buttonTransparentLink,
    buttonTransparentText,
    bigSectionImage,
    bigSectionHeading,
    bigSectionDescription,
    priceSection,
    firstUpdateIcons,
    firstUpdateHeading,
    firstUpdateInclusions,
    secondUpdateIcons,
    secondUpdateHeading,
    secondUpdateInclusions,
    thirdUpdateIcons,
    thirdUpdateHeading,
    thirdUpdateInclusions,
    sponsorAMomHeading,
    sponsorAMomDescription,
    sponsorAMomVideo,
    sponsorSectionText,
    sponsorSectionDescription,
    sponsorButtonText
  } = data.allContentfulPageSponsorAMom.edges[0].node;
  const {
    heading,
    description: sponsorDesc,
    sponsorship1Img,
    sponsorship1Heading,
    sponsorship1Desc,
    sponsorship1Link,
    sponsorship1LinkText,
    sponsorship3Img,
    sponsorship3Heading,
    sponsorship3Desc,
    sponsorship3Link,
    sponsorship3LinkText
  } = data.allContentfulSectionOtherSponsorships.edges[0].node;
  let firstUpdateInclusionsDiv = '';
  let secondUpdateInclusionsDiv = '';
  let thirdUpdateInclusionsDiv = '';

  // we are doing this so that we can divide the content into 3 columns
  let chunkedArr1 = chunk(firstUpdateInclusions, Math.ceil(firstUpdateInclusions.length / 10));
  let chunkedArr2 = chunk(secondUpdateInclusions, Math.ceil(secondUpdateInclusions.length / 10));
  let chunkedArr3 = chunk(thirdUpdateInclusions, Math.ceil(thirdUpdateInclusions.length / 10));

  for (let i = 0; i < chunkedArr1.length; i++) {
    console.log(firstUpdateIcons[i].file);
    firstUpdateInclusionsDiv += `<div class="col-md-4">
    <ul>`;
    chunkedArr1[i].map(inclusion => {
      return (firstUpdateInclusionsDiv += `<div key="${inclusion}" class="mt60 d-flex flex-column text-center"><div class="update-img"><img src="${firstUpdateIcons[i].file.url}" /></div><div class="mt30 inclusion-txt">${inclusion}</div></div>`);
    });
    firstUpdateInclusionsDiv += `</ul>
    </div>`;
  }

  for (let i = 0; i < chunkedArr2.length; i++) {
    secondUpdateInclusionsDiv += `<div class="col-md-4">
    <ul>`;
    chunkedArr2[i].map(inclusion => {
      return (secondUpdateInclusionsDiv += `<div key="${inclusion}" class="mt60 d-flex flex-column text-center"><div class="update-img"><img src="${secondUpdateIcons[i].file.url}" /></div><div class="mt30 inclusion-txt">${inclusion}</div></div>`);
    });
    secondUpdateInclusionsDiv += `</ul>
    </div>`;
  }

  for (let i = 0; i < chunkedArr3.length; i++) {
    thirdUpdateInclusionsDiv += `<div class="col-md-4">
    <ul>`;
    chunkedArr3[i].map(inclusion => {
      return (thirdUpdateInclusionsDiv += `<div key="${inclusion}" class="mt60 d-flex flex-column text-center"><div class="update-img"><img src="${thirdUpdateIcons[i].file.url}" /></div><div class="mt30 inclusion-txt">${inclusion}</div></div>`);
    });
    thirdUpdateInclusionsDiv += `</ul>
    </div>`;
  }

  return (
    <Layout>
      <HelmetComponent description={description} image={headerImage.fluid.src} title={title} />
      <section className="hero" id="sponsor-hero">
        <div id="page-sub-header">
          <Img className="w-100 bg-img-overlay position-absolute" fluid={headerImage.fluid} />
          <Img className="w-100 bg-img position-absolute" fluid={headerImage.fluid} />
          <div className="container">
            <div className="page-sub-header-content">
              <div className="row">
                <div className="col-lg-7 col-md-9 col-xs-12">
                  <h1>{title}</h1>
                  <p>{description.description}</p>
                  <div className="btn-group"></div>
                  <div className="row mt-3">
                    <div className="col-md-5 mt-4">
                      <a className="btn-reg btn-white mom-donate-btn" data-formid="2940" href="#">
                        {buttonWhiteText}
                      </a>
                    </div>
                    <div className="col-md-6 mt-3">
                      <a className="btn-trans" data-toggle="modal" href="#sponsorMomModal">
                        <i className="material-icons">play_circle_outline</i>
                        {buttonTransparentText}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="sponsorMomModal">
          <div className="modal-dialog">
            <div className="modal-content transparent-modal">
              <div className="modal-header">
                <button aria-hidden="true" className="close" data-dismiss="modal" type="button">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="embed-container">
                  <iframe
                    allowFullScreen
                    data-src={buttonTransparentLink}
                    frameBorder="0"
                    id="sponsorMomVideo"
                    title="about"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-area" id="primary">
        <main className="site-main mt-20" id="main" role="main">
          <section className="two-columns" id="section-sponsor-a-mom">
            <div className="container">
              <div className="two-columns-content row">
                <div className="col-md-6">
                  <Img alt="Sponsor a mom" fluid={bigSectionImage.fluid} />
                </div>
                <div className="col-md-6">
                  <h2>{bigSectionHeading}</h2>
                  <p>{bigSectionDescription.bigSectionDescription}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="two-columns pb40" id="section-updates-intro">
            <div className="container">
              <div className="two-columns-content">
                <div className="cols">
                  <h2
                    className="w65"
                    dangerouslySetInnerHTML={{
                      __html: priceSection.priceSection
                    }}
                  ></h2>
                </div>
                <div className="cols"></div>
              </div>
            </div>
          </section>

          <section className="two-columns first-update" id="section-updates-main">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h3>{firstUpdateHeading}</h3>
                </div>
              </div>
              <div
                className="row"
                dangerouslySetInnerHTML={{ __html: firstUpdateInclusionsDiv }}
              ></div>
            </div>
          </section>

          <section className="two-column second-update" id="section-updates-main">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h3>{secondUpdateHeading}</h3>
                </div>
              </div>
              <div
                className="row"
                dangerouslySetInnerHTML={{ __html: secondUpdateInclusionsDiv }}
              ></div>
            </div>
          </section>

          <section className="two-columns third-update" id="section-updates-main">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h3>{thirdUpdateHeading}</h3>
                </div>
              </div>
              <div
                className="row"
                dangerouslySetInnerHTML={{ __html: thirdUpdateInclusionsDiv }}
              ></div>
            </div>
          </section>

          <section className="sponsor-other-ways" id="section-sponsor-story">
            <div className="container">
              <div className="row">
                <div className="col-md-7 text-left mb-5">
                  <h2>{sponsorAMomHeading}</h2>
                  <p>{sponsorAMomDescription.sponsorAMomDescription}</p>
                </div>
              </div>
            </div>

            <div className="container">
              <div>
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <iframe
                      allowFullScreen
                      className="w-100 iframeVid"
                      data-src={sponsorAMomVideo}
                      frameBorder="0"
                      title="testimonial"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-cta" id="section-help-a-mom">
            <div className="section-cta-inner">
              <div className="container">
                <div className="section-cta-content row">
                  <div className="col-md-6">
                    <h2>{sponsorSectionText}</h2>
                    <p>{sponsorSectionDescription.sponsorSectionDescription}</p>
                  </div>
                  <div>
                    <a
                      className="btn-reg btn-purple float-right mom-donate-btn"
                      data-formid="2940"
                      href="#"
                    >
                      {sponsorButtonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-other-ways sponsor-other-ways" id="section-other-ways">
            <div className="container">
              <div className="row">
                <div className="col-md-7 text-left mb80">
                  <h2>{heading}</h2>
                  <p>{sponsorDesc.description}</p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6 cards">
                  <div className="sponsor-col-img text-left">
                    <Img alt="Sponsor a mom icon" fluid={sponsorship1Img.fluid} />
                  </div>
                  <h3>{sponsorship1Heading}</h3>
                  <p>{sponsorship1Desc.sponsorship1Desc}</p>
                  <a href={sponsorship1Link} className="learnmore-btn">
                    {sponsorship1LinkText}
                  </a>
                </div>
                <div className="col-md-6 cards">
                  <div className="sponsor-col-img">
                    <Img alt="Make donations icon" fluid={sponsorship3Img.fluid} />
                  </div>
                  <h3>{sponsorship3Heading}</h3>
                  <p>{sponsorship3Desc.sponsorship3Desc}</p>
                  <a className="learnmore-btn" href={sponsorship3Link}>
                    {sponsorship3LinkText}
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>
    </Layout>
  );
}

export const sponsorMomPageQuery = graphql`
  query SponsorMomPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulPageSponsorAMom {
      edges {
        node {
          headerImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          title
          description {
            description
          }
          buttonWhiteLink
          buttonWhiteText
          buttonTransparentLink
          buttonTransparentText
          bigSectionImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          bigSectionHeading
          bigSectionDescription {
            bigSectionDescription
          }
          priceSection {
            priceSection
          }
          firstUpdateHeading
          firstUpdateInclusions
          secondUpdateHeading
          secondUpdateInclusions
          thirdUpdateHeading
          thirdUpdateInclusions
          firstUpdateIcons {
            file {
              url
            }
          }
          secondUpdateIcons {
            file {
              url
            }
          }
          thirdUpdateIcons {
            file {
              url
            }
          }
          sponsorAMomHeading
          sponsorAMomDescription {
            sponsorAMomDescription
          }
          sponsorAMomVideo
          sponsorSectionText
          sponsorSectionDescription {
            sponsorSectionDescription
          }
          sponsorButtonText
          sponsorButtonLink
        }
      }
    }
    allContentfulSectionOtherSponsorships {
      edges {
        node {
          heading
          description {
            description
          }
          sponsorship1Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship1Heading
          sponsorship1Desc {
            sponsorship1Desc
          }
          sponsorship1Link
          sponsorship1LinkText
          sponsorship3Img {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
          }
          sponsorship3Heading
          sponsorship3Desc {
            sponsorship3Desc
          }
          sponsorship3Link
          sponsorship3LinkText
        }
      }
    }
  }
`;
